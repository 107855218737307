import { BsDashCircleFill, BsPlusCircleFill } from "react-icons/bs";
import { Product, Products } from "./Products";
import React, { useEffect, useRef, useState } from "react";
import {
	createCheckout,
	getAllProducts,
	getAllStock,
} from "../components/services/ProductService";

import ProductOverlay from "./ProductOverlay";
import StoreHeader from "./StoreHeader";
import cart from "../components/img/cart-outline.png";
import group from "../components/img/store/group.JPG";
import group2 from "../components/img/store/group2.JPG";
import group3 from "../components/img/store/group3.JPG";
import group4 from "../components/img/store/group4.JPG";

const CartItems = ({ lineItems, setLineItems, products }) => {
	const incrementItem = ({ name, amount }) => {
		console.log(products);
		console.log("^");
		const newLineItems = [...lineItems];
		for (let i = 0; i < newLineItems.length; i++) {
			if (newLineItems[i].name === name) {
				const maxAmt = products.find(
					(p) =>
						newLineItems[i].name ===
						(newLineItems[i].name.includes("-")
							? `${p.size}-${p.name}`
							: p.name)
				).stock;
				console.log(`Max: ${maxAmt}`);
				if (
					newLineItems[i].quantity + amount < 0 ||
					newLineItems[i].quantity + amount > maxAmt
				) {
					return;
				} else {
					newLineItems[i].quantity += amount;
				}
			}
		}
		setLineItems(newLineItems);
	};

	return (
		<div
			className="cartItem"
			style={{
				position: "relative",
				flex: 1,
				overflow: "scroll",
				padding: "0 16px",
			}}
		>
			{lineItems.map((item, index) => {
				const thisProduct = Products.find(
					(p) =>
						p.name ===
						(item.name.includes("-") ? item.name.split("-")[1] : item.name)
				);
				const maxAmt = products.find(
					(p) =>
						item.name ===
						(item.name.includes("-") ? `${p.size}-${p.name}` : p.name)
				).stock;
				return (
					<div
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							margin: "12px 0",
						}}
						key={`${thisProduct.name}-${index}`}
					>
						<img
							src={thisProduct.images[0]}
							style={{ height: "40px", borderRadius: "8px" }}
							alt={item.name}
						/>
						<div>
							<p>
								<strong>{item.name.replace("-", " ")}</strong>
							</p>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}
							>
								<BsDashCircleFill
									onClick={() => incrementItem({ name: item.name, amount: -1 })}
									style={{
										cursor: item.quantity === 0 ? "default" : "pointer",
										opacity: item.quantity === 0 ? 0.3 : 1,
									}}
								/>
								<p style={{ margin: "0 8px" }}>{item.quantity} </p>
								<BsPlusCircleFill
									onClick={() => incrementItem({ name: item.name, amount: 1 })}
									style={{
										cursor: item.quantity === maxAmt ? "default" : "pointer",
										opacity: item.quantity === maxAmt ? 0.3 : 1,
									}}
								/>
							</div>
						</div>
					</div>
				);
			})}
		</div>
	);
};

const imgs = [group, group2, group3, group4];

const Store = () => {
	const [products, setProducts] = useState([]);
	const [selectedProduct, setSelectedProduct] = useState(null);
	const [tossRef, setTossRef] = useState(null);
	const [lineItems, setLineItems] = useState([]);
	const [cartOpen, setCartOpen] = useState(false);

	const cartRef = useRef();

	const addItemsToCart = ({ product, sizes }) => {
		const newLineItems = [...lineItems];

		cartRef.current.classList.add("shake");
		setTimeout(() => {
			cartRef.current.classList.remove("shake");
		}, 1000);

		// ITEMS WITHOUT A SIZE
		if (sizes === undefined) {
			for (let i = 0; i < newLineItems.length; i++) {
				if (newLineItems[i].name === product.name) {
					newLineItems[i].quantity += 1;
					setLineItems(newLineItems);
					return;
				}
			}
			newLineItems.push({
				name: product.name,
				quantity: 1,
			});
			setLineItems(newLineItems);
			return;
		}

		// ITEMS WITH A SIZE
		for (const size of sizes) {
			if (size.quantity > 0) {
				let found = false;
				for (let i = 0; i < newLineItems.length; i++) {
					if (newLineItems[i].name === `${size.size}-${product.name}`) {
						found = true;
						newLineItems[i].quantity += size.quantity;
					}
				}
				if (!found) {
					newLineItems.push({
						name: `${size.size}-${product.name}`,
						quantity: size.quantity,
					});
				}
			}
		}
		setLineItems(newLineItems);
	};

	const getItemCount = () => {
		let count = 0;
		for (let item of lineItems) {
			count += item.quantity;
		}
		return count;
	};

	const getAllPrices = async (array) => {
		const allResults = [];

		for (let i = 0; i < array.length; i++) {
			const noSize = !array[i].name.includes("-");
			const thisSize = noSize ? null : array[i].name.split("-")[0];
			const thisName = noSize ? array[i].name : array[i].name.split("-")[1];
			// const result = await getPrice(array[i].default_price);
			let newItem = array[i];
			// newItem.unit_amount = result.unit_amount;
			newItem.name = thisName;
			newItem.size = thisSize;
			allResults.push(newItem);
		}
		return allResults;
	};

	useEffect(() => {
		getAllProducts().then((response) => {
			let theseProducts = [...response.data].filter((prod) => prod.active);
			theseProducts.sort((a, b) => (a.name > b.name ? 1 : -1));
			getAllPrices(theseProducts).then((result) => {
				getAllStock(result).then(() => {
					setProducts(result);
				});
			});
		});
	}, []);

	const createLineItems = () => {
		console.log("creating");
		console.log(Products);
		const stripeItems = [];
		lineItems.forEach((item) => {
			if (item.quantity > 0) {
				const itemNameSplit = item.name.split("-");
				const itemName =
					itemNameSplit.length > 1 ? itemNameSplit[1] : itemNameSplit[0];
				const size = itemNameSplit.length > 1 ? itemNameSplit[0] : null;
				const thisProduct = Products.find((p) => itemName === p.name);
				stripeItems.push({
					data: {
						price_data: size
							? thisProduct.sizes.find((s) => s.size === size).price_data
							: thisProduct.price_data,
						quantity: item.quantity,
					},
					description: {
						name: thisProduct.name,
						description: thisProduct.description,
						src: thisProduct.images[0],
					},
				});
			}
		});
		return stripeItems;
	};

	const goToCheckout = () => {
		const stripeLineItems = createLineItems();
		var windowReference = window.open("", "_blank");
		createCheckout({
			cancelUrl: "http://sidewinderva.com/storecancel",
			successUrl: "http://sidewinderva.com/storesuccess",
			lineItems: stripeLineItems,
		}).then((response) => {
			windowReference.location = response.url;
		});
	};

	return (
		<div>
			<div className="content" style={{ textAlign: "center" }}>
				<div className={`cart${cartOpen ? " open" : ""}`}>
					<div className="cartBanner">
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
							}}
						>
							<img src={cart} alt="Cart Icon" />
							<h3>Your Cart</h3>
						</div>
						<div ref={cartRef}>
							{lineItems.length > 0 ? (
								<h3
									style={{ textDecoration: "underline", cursor: "pointer" }}
									onClick={() => {
										if (cartOpen) {
											const newLineItems = [...lineItems];
											for (let i = newLineItems.length - 1; i >= 0; i--) {
												if (newLineItems[i].quantity === 0) {
													newLineItems.splice(i, 1);
												}
											}
											setLineItems(newLineItems);
										}
										setCartOpen(!cartOpen);
									}}
								>
									{cartOpen
										? "Close Cart View"
										: `Checkout (${getItemCount()})`}
								</h3>
							) : (
								<h3>Cart is empty</h3>
							)}
						</div>
					</div>
					{cartOpen ? (
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								maxHeight: "40vh",
								alignItems: "center",
							}}
						>
							<CartItems
								lineItems={lineItems}
								setLineItems={setLineItems}
								products={products}
							/>
							<button
								className="cartButton"
								onClick={() => goToCheckout()}
								disabled={getItemCount() === 0}
								style={
									getItemCount() === 0
										? { opacity: 0.5, cursor: "default" }
										: {}
								}
							>
								Checkout
							</button>
						</div>
					) : null}
				</div>

				<h1>Sidewinder Store</h1>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						flexDirection: "column",
					}}
				>
					<StoreHeader />
					<p>
						Thank you for supporting our athletes by buying team gear!{" "}
						<strong>
							Any order you place today will be delivered to your athlete at
							their next practice!
						</strong>{" "}
						If you'd like your apparel shipped, please send us an email after
						ordering.
					</p>
					<div
						style={{
							display: "flex",
							flexWrap: "wrap",
							alignItems: "stretch",
							justifyContent: "center",
						}}
					>
						{Products.map((product, index) => {
							return (
								<Product
									product={product}
									products={products}
									index={index}
									setSelectedProduct={setSelectedProduct}
									setTossRef={setTossRef}
									key={product.name}
									addItemsToCart={addItemsToCart}
								/>
							);
						})}
					</div>
				</div>
				{selectedProduct ? (
					<ProductOverlay
						product={selectedProduct}
						maximumSizes={products
							.filter(
								(p) => p.name === selectedProduct.name && p.stock !== undefined
							)
							.map((p) => {
								const thisSize = lineItems.find(
									(i) => i.name == `${p.size}-${selectedProduct.name}`
								);
								return {
									size: p.size,
									quantity: p.stock - (thisSize ? thisSize.quantity : 0),
								};
							})}
						setSelectedProduct={setSelectedProduct}
						tossRef={tossRef}
						addItemsToCart={addItemsToCart}
						lineItems={lineItems}
					/>
				) : null}
			</div>
		</div>
	);
};

export default Store;
