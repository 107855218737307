import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import girlsteam from "../../../components/img/heroimgs/girlsteam.jpg";
import Coaches from "../../../AboutUs/Staff/Coaches";

const CoachLink = ({ coach }) => {
	return (
		<Link to={`/staff/Girls/${coach.name.replace(" ", "").toLowerCase()}`}>
			{coach.name}
		</Link>
	);
};

function Tryouts() {
	return (
		<div className="content">
			<Helmet>
				<title>Girl's Season</title>
				<meta
					name="description"
					content="All aboout our girl's youth volleyball club season in Tucson, Arizona."
				/>
			</Helmet>
			<h1>2024 Girl's Season</h1>
			<h4 style={{ margin: "0" }}>
				<Link to="/tryoutprocess">Follow this guide to sign up!</Link>
			</h4>
			<div style={{ textAlign: "center", marginTop: "32px" }}>
				<h2>Girls 10u (5th Grade) - 14u (8th grade):</h2>
				<h5>
					13u and 14u tryouts are complete. Teams can be found{" "}
					<Link to="/teams/Girls/14-1">here</Link>.
				</h5>
				<h2>Girls 15u (Freshmen) - Girls 17u (Juniors):</h2>
				<h5>
					Tryouts: Saturday November 30th at AZ State School for the Deaf and
					Blind. <a href="http://dashboard.sidewinderva.com">REGISTER HERE</a>
				</h5>
				<h5>16u/17u: 2:00 - 4:30 pm</h5>
				<h5>15u: 5:00 - 7:30 pm</h5>
				<h3>Projected Teams:</h3>
				<h5>
					17-1 (Region Team) - Head Coach:{" "}
					<CoachLink coach={Coaches.kneerhof} />, Assistant:{" "}
					<CoachLink coach={Coaches.alopez} />
				</h5>
				<h5>
					16-1 (Travel Team) - Head Coach: <CoachLink coach={Coaches.jserna} />,
					Assistant: <CoachLink coach={Coaches.sgillman} />
				</h5>
				<h5>
					16-2 (Region Team) - Head Coach:{" "}
					<CoachLink coach={Coaches.jdevries} />, Assistant:{" "}
					<CoachLink coach={Coaches.slopez} />
				</h5>
				<h5>
					15-1 (Travel Team) - Head Coach: <CoachLink coach={Coaches.kwells} />,
					Assistant: <CoachLink coach={Coaches.adewitt} />
				</h5>
				<h5>
					15-2 (Region Team) - Head Coach:{" "}
					<CoachLink coach={Coaches.rbautista} />, Assistant: TBD
				</h5>
				<div className="pageHero">
					<img src={girlsteam} />
				</div>
			</div>
			<h1>FAQs</h1>
			<h3>What Age Should I Tryout For?</h3>
			<p>
				When you register your athlete for tryouts using the steps linked above,
				their "club age" is now listed directly on the registration portal.
			</p>
			<h3>How does the club season work?</h3>
			<p>
				Each Sidewinder team is made up of 9-13 kids from all around Tucson.
				Teams are broken up according to their age and their may be more than
				one team per age group. Usually one team in each age group will be a
				"travel" team and the rest will be "region" teams.
			</p>
			<p>
				All Sidewinder teams play in the Arizona Region of USA Volleyball.
				Arizona Region tournaments are half-day tournaments, typically on
				Saturday, where our teams are matched up against other club teams from
				around Arizona according to their skill level. Tournaments are typically
				hosted in Phoenix. Each team practices two times per week at night at
				one of our partner schools. We currently use Tucson High School and
				Arizona School for the Deaf and Blind for practices. Both are located
				off of Speedway and I-10.
			</p>
			<h3>What is a region team?</h3>
			<p>
				Region teams have 6 half day tournaments (starting at either 8am or 3pm)
				and 1 full day tournament for the Arizona Region Championships. Region
				teams also participate in the Cactus Classic over MLK weekend. Teams
				will start practicing in January and end with the Region Championship
				tournament in May.
			</p>
			<p>
				We do not travel as a team - each family is responsible for their own
				travel to and from tournaments. There are no multi-day out-of-town
				tournaments for region teams, so you will not need to worry about
				hotels.
			</p>
			<h3>Region Tournaments</h3>
			<p>
				ALL teams play in region tournaments. Travel teams ALSO play in the
				travel tournaments listed below.
			</p>
			<table>
				<thead>
					<tr>
						<td>Tournament</td>
						<td>17u Teams</td>
						<td>15u/16u Teams</td>
						<td>13u/14u Teams</td>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>Region 1</td>
						<td>Jan 4th</td>
						<td>Jan 11</td>
						<td>Jan 11</td>
					</tr>
					<tr>
						<td>Cactus Classic</td>
						<td>Jan 18th - 20th</td>
						<td>Jan 18th - 20th</td>
						<td>Jan 18th - 20th</td>
					</tr>
					<tr>
						<td>Region 2</td>
						<td>Jan 25th</td>
						<td>Feb 1</td>
						<td>Feb 1</td>
					</tr>
					<tr>
						<td>Region 3</td>
						<td>Feb 8th</td>
						<td>Feb 22nd</td>
						<td>Feb 22nd</td>
					</tr>
					<tr>
						<td>Region 4</td>
						<td>Mar 1st</td>
						<td>Mar 8th</td>
						<td>Mar 8th</td>
					</tr>
					<tr>
						<td>Region 5</td>
						<td>Mar 22nd</td>
						<td>Mar 15th</td>
						<td>Mar 15th</td>
					</tr>
					<tr>
						<td>Region 6</td>
						<td>Apr 19th</td>
						<td>Mar 29th</td>
						<td>Mar 29th</td>
					</tr>
					<tr>
						<td>Region Championships</td>
						<td>Apr 26th</td>
						<td>Apr 26th</td>
						<td>May 3rd</td>
					</tr>
				</tbody>
			</table>
			<h3>What is a travel team?</h3>
			<p>
				Travel teams have all of the same tournaments as the region teams but
				also compete nationally. Every travel team will compete in the Fiesta
				Festival in Phoenix and the AAU national championship tournament in
				Orlando.
			</p>
			<p>
				We do not travel as a team - each family is responsible for their own
				travel and lodging. There are TWO multi-day out-of-town tournaments. The
				Fiesta Festival tournament will require a 3-night hotel stay and AAU
				National tournament will require a 4-night hotel stay.
			</p>
			<h3>Travel Tournaments</h3>
			<p>15-1 and 16-1 are the only travel teams in 2025</p>
			<table>
				<thead>
					<tr>
						<td>Tournament</td>
						<td>15-1</td>
						<td>16-1</td>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>Fiesta Festival (Phoenix)</td>
						<td>February 15-17</td>
						<td>February 15-17</td>
					</tr>
					<tr>
						<td>Nationals (Orlando)</td>
						<td>June 25-28</td>
						<td>June 21-24</td>
					</tr>
				</tbody>
			</table>
			<h3>Total Season Cost</h3>
			<ul>
				<li>Tryouts are $25</li>
				<li>
					<i>All</i> athletes will need a USA Volleyball membership for $65
				</li>
				<li>
					<i>Travel team</i> athletes will need an AAU membership for $20
				</li>
			</ul>
			After making a team, dues will be structured as shown below. These dues
			cover everything our teams need for the season,{" "}
			<strong>there are no extra fees.</strong>
			<p>
				<strong>Region Teams</strong>
			</p>
			<p>
				Region teams attend 6 region tournaments in Phoenix against other clubs
				in the Arizona Region. Girl's region teams will also participate in the
				Cactus Classic (Tucson Convention Center) over MLK weekend against teams
				from around the country. The region team season ends with the region
				championships in late April/early May.
			</p>
			<table>
				<thead>
					<tr>
						<td>Due Date</td>
						<td>Monthly Plan</td>
						<td>Pay In Full Plan</td>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>Directly After Tryouts</td>
						<td>$400</td>
						<td>$400</td>
					</tr>
					<tr>
						<td>Dec 5th, 2022</td>
						<td>$0</td>
						<td>$1450</td>
					</tr>
					<tr>
						<td>Jan 1st, 2023</td>
						<td>$400</td>
						<td>$0</td>
					</tr>
					<tr>
						<td>Feb 1st, 2023</td>
						<td>$400</td>
						<td>$0</td>
					</tr>
					<tr>
						<td>Mar 1st, 2023</td>
						<td>$400</td>
						<td>$0</td>
					</tr>
					<tr>
						<td>Apr 1st, 2023</td>
						<td>$400</td>
						<td>$0</td>
					</tr>
					<tr className="boldrow">
						<td>Total</td>
						<td>$2000</td>
						<td>$1850</td>
					</tr>
				</tbody>
			</table>
			<p>
				<strong>Travel Teams</strong>
				<p>
					In addition to the 6 region tournaments and Cactus Classic (Tucson,
					MLK Weekend), girl's travel teams also go to a national level travel
					tournament (April) and the AAU Girls Junior National Championship
					(June).
				</p>
			</p>
			<table>
				<thead>
					<tr>
						<td>Due Date</td>
						<td>Monthly Plan</td>
						<td>Pay In Full Plan</td>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>Directly After Tryouts</td>
						<td>$500</td>
						<td>$500</td>
					</tr>
					<tr>
						<td>Dec 5th, 2022</td>
						<td>$0</td>
						<td>$2350</td>
					</tr>
					<tr>
						<td>Jan 1st, 2023</td>
						<td>$500</td>
						<td>$0</td>
					</tr>
					<tr>
						<td>Feb 1st, 2023</td>
						<td>$500</td>
						<td>$0</td>
					</tr>
					<tr>
						<td>Mar 1st, 2023</td>
						<td>$500</td>
						<td>$0</td>
					</tr>
					<tr>
						<td>Apr 1st, 2023</td>
						<td>$500</td>
						<td>$0</td>
					</tr>
					<tr>
						<td>May 1st, 2023</td>
						<td>$500</td>
						<td>$0</td>
					</tr>
					<tr className="boldrow">
						<td>Total</td>
						<td>$3000</td>
						<td>$2850</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
}
export default Tryouts;
