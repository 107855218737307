import aidenk from "../../components/img/coaches/aidenk.png";
import aidenv from "../../components/img/coaches/aidenv.JPG";
import alexa from "../../components/img/coaches/alexa.jpg";
import andrew from "../../components/img/coaches/andrew.jpg";
import aurora from "../../components/img/coaches/aurora.png";
import ayda from "../../components/img/coaches/ayda.JPG";
import boesen from "../../components/img/coaches/kyleb.JPG";
import bowers from "../../components/img/coaches/brian.jpg";
import brett from "../../components/img/coaches/brett.JPG";
import carlee from "../../components/img/coaches/carlee.jpg";
import carson from "../../components/img/coaches/carson.JPG";
import cheyenne from "../../components/img/coaches/cheyenne.JPG";
import cheyenner from "../../components/img/coaches/cheyenner.png";
import franklin from "../../components/img/coaches/franklin.JPG";
import fiona from "../../components/img/coaches/fiona.jpg";
import huff from "../../components/img/coaches/stephen.jpg";
import jane from "../../components/img/coaches/jane.jpeg";
import jared from "../../components/img/coaches/jared.png";
import jules from "../../components/img/coaches/jules.JPG";
import keifer from "../../components/img/coaches/keifer.JPG";
import kevin from "../../components/img/coaches/kevin.jpg";
import kyle from "../../components/img/coaches/kyle.jpg";
import laurent from "../../components/img/coaches/laurent.JPG";
import leah from "../../components/img/coaches/leah.png";
import lorenzo from "../../components/img/coaches/lorenzo.jpeg";
import matea from "../../components/img/coaches/matea.jpg";
import mattb from "../../components/img/coaches/mattb.jpeg";
import mott from "../../components/img/coaches/heather.jpg";
import nickel from "../../components/img/coaches/nikki.jpg";
import rueben from "../../components/img/coaches/rueben.jpeg";
import sam from "../../components/img/coaches/sam.png";
import shane from "../../components/img/coaches/shane.jpg";
import tristan from "../../components/img/coaches/tristan.JPG";
import zach from "../../components/img/coaches/zach.JPG";

const Coaches = {
  //Andrew
  aadair: {
    name: "Andrew Adair",
    type: "Director",
    title: "Co-Director, Administrator",
    paragraphs: [
      `Andrew Adair began coaching boys' club teams in 2018 with Arizona Premier Volleyball (APV) and co-founded Sidewinder
      Volleyball Club as a girls' club in 2022. The clubs merged in 2023, and Andrew now serves as co-director for both the
      boys' and girls' in Tucson.`,
      `Andrew became the head varsity coach at <a href="http://foothillsvolleyball.com" target="_blank" rel="noreferrer">Catalina
      Foothills High School</a> in 2022. Before this, he coached for four years
      at Rincon/University High School. As the Varsity coach for both the girls' and boys' varsity teams at Catalina Foothills,
      his boys' teams have consistently finished in the top-10, and his girls' teams in the top-15. His teams have won several
      in-season invitationals, including the TUSD Tournament, Lancer Invitational, and Arizona College Prep's "Battle for The Realm".`,
      `This summer, Andrew was selected to be the <a href="https://www.azregionvolleyball.org/highperformance"
      target="_blank" rel="noreferrer">Arizona Region High Performance (AZHP)</a> 17U assistant coach. The AZHP team is a group of
      the best 12 boys volleyball players in the state, selected by the Arizona Region.  Andrew's AZHP team took 5th place in the USA Volleyball All Start tournament'.`,
      `Andrew played four years of collegiate volleyball as an Outside Hitter at the University of Arizona, leading his team to a Final
      Four and earning 1st Team All-Tournament honors. In high school, he reached the State Tournament all four years and was the state
      runner-up in 2011.`,
      `Andrew is the founder of <a href="http://stattogether.com" target="_blank" rel="noreferrer">Stat Together</a>,
      a mobile app for managing team stats and live streaming matches.`,
    ],
    image: {
      src: andrew,
      caption:
        "Coach Andrew is the Varsity coach for the Catalina Foothill's girls and boys teams.",
    },
  },
  //Kyle
  kwells: {
    name: "Kyle Wells",
    type: "Director",
    title: "Co-Director, Head of Development",
    image: {
      src: kyle,
      caption:
        "As the head of development, Coach Kyle runs the Sidewinder Summer Clinics",
    },
    paragraphs: [
      `Kyle Wells co-founded Sidewinder as a girl's club in 2022 while
    coaching boy's teams at Arizona Premier Volleyball.  The two clubs merged in 2023
    and Kyle now acts as co-director for both the boys and girls. Kyle is the JV-A
    head coach at Catalina Foothills for both the girl's and boy's programs. Outside of
    coaching, Kyle founded <a href="https://sites.google.com/view/the-grass-valley-vb/home"
    target="_blank" rel="noreferrer"> The Grass Valley Volleyball VB</a>, where he runs
    competitive grass volleyball tournaments throughout the state of Arizona.`,
      `Kyle is a
    graduate of the University of Arizona.  At Arizona, Kyle played 4 years of collegiate
    volleyball as an Outside Hitter.  During his 4 years, Kyle served as the President for
    2 seasons and helped the team to the Elite 8 of the NCVF National Tournament.  Before
    coming to Arizona, he played at Warren Township High School in Illinois, and was named
    to the All-State team his senior year. Kyle also played for Adversity Volleyball Club
    where his team finished in the top 10 in the open division of USAV Nationals for two
    years in a row.`,
    ],
  },
  //Mott
  hmott: {
    name: "Heather Mott",
    image: {
      src: mott,
      caption: "The Mott family after winning the 21-22 5A State Champtionship",
    },
    paragraphs: [
      `Coach Mott has been coaching girls and boys high school and club volleyball for almost 20 years.  She has recorded 4 carreer state final appearances, 2 times as runner up, and 2 times state champion.   Volleyball is a family affair with 3 of 4 kids (so far) having successful careers playing at the next level.  `,
    ],
  },
  //Laurent
  lriel: {
    name: "Laurent Riel",
    image: {
      src: laurent,
      caption: "Laurent with the 14-1 team at the 2023 SoCal Cup Winter Formal",
    },
    paragraphs: [
      `Coach Laurent started coaching Sidewinder boys teams in 2016 and has coached nearly every age group.  Most recently, Laurent
    coached the 2022-2023 14u team to a bronze medal finish at the USA Volleyball national championships.  Outside of Sidewinder, Laurent also 
    acts as one of the assistant coached to the University of Arizona Women's Club team.
    `,
      `Laurent is an Arizona native and played 2 years at Rincon/University High School and Arizona Premier Volleyball.  With APV, Laurent
    placed 3rd at the 18u USAV National Championships in 2013.  In college, Laurent joined the Men's team at the University of Arizona.  As a starting
    middle, Laurent's and his University of Arizona team won the D3 National Championship in 2016.`,
    ],
  },
  //Huff
  shuff: {
    name: "Stephen Huff",
    image: {
      src: huff,
      caption:
        "Steve after winning a national championship in his first year as a coach",
    },
    paragraphs: [
      `
    After playing as a setter for 2 years, Steve switched to coaching the U of A men’s B team won back to back national championships in his first 2 years.
    Since then, he added a 3rd place finish in 2019 and another championship in 2022.
    Steve’s been coaching both the girls and boys varsity teams and teaching at Rincon/University High since 2018.
    Steve has been with Sidewinder (formerly APV) since 2016 and has coached 15s, 16s, 17s and 18s teams.
    In the last two summer seasons, Steve has lead his 18s teams to gold bracket finishes at nationals.`,
      `Stephen Huff graduated from Buena High School in 2009 and played volleyball all 4 years.
    After graduating, Steve went on to played 2 years at the U of A.  In those years his team placed 2nd and 5th at nationals.`,
    ],
  },
  //Rueben
  rbautista: {
    name: "Rueben Bautista",
    image: {
      src: rueben,
      caption: "Rueben with the UArizona Women's Club Team",
    },
    paragraphs: [
      `Rueben began coaching at Rincon/University High School in 2019, where he also worked as a math teacher.
    In 2021, he joined as head coach for the University of Arizona Women’s Club Volleyball team and coached with director Andrew Adair.
    He is now in his fourth season with the U of A team.`,
      `Rueben attended the University of Arizona from 2013-2017.
    Playing as a setter for the men’s club, under head coach Stephen Huff.
    In 2015, his U of A squad went on to win the NCVF national championships.`,
    `Outside of volleyball, Rueben utilizes his statistics and teaching backgrounds to head up the
    Data & Insights team at Madden Media, an industry-leading tourism marketing agency based out of Tucson, Arizona.`,
    ],
  },
  //Lorenzo
  lrocha: {
    name: "Lorenzo Rocha",
    image: {
      src: lorenzo,
      caption: "Lorenzo coaching the 2023 17-1 boys",
    },
    paragraphs: [
      `Lorenzo graduated from University High School where he
      started on the volleyball team all 4 years. He is currently
      studying Biosystems Engineering at the University of
      Arizona and plays as libero on the U of A Men’s Volleyball
      team. Lorenzo earned All Tournament Team at Nationals and
      helped his team finish top 5 in the tournament in 2024.
      Lorenzo has been with Sidewinder (formerly APV) since 2017
      as a player and started coaching in 2022. He has coached
      14s, 15s, and 17s teams. `,
    ],
  },
  // Keifer
  kneerhof: {
    name: "Keifer Neerhof",
    image: {
      src: keifer,
      caption: "Keifer coaching the 2024 17-1 Team",
    },
    paragraphs: [
      `With over 10 years of immersive playing experience at the collegiate, club and high school level,
      Keifer Neerhof is bringing energetic passion for volleyball and transitioning seamlessly into a coaching role.
      Equipped with an understanding of the game's nuances and strategies through years of competitive play at the high school
      level  at Canyon Del Oro, club with APV and then collegiatly with the University of Arizona Men’s Volleyball. Keifer is
      translating his on-court expertise into effective coaching methodologies to elevate team performance. He is committed to
      instilling a strong work ethic, communication, strategic thinking, and a passion for the sport in aspiring athletes.
      He is excited to bring his personal insights and perspectives to cultivate a culture of excellence and achievement within
      the team he coaches.`,
    ],
  },
  //Jane
  jdevries: {
    name: "Jane DeVries",
    image: {
      src: jane,
      caption: "Jane coaching the 2023 16-3 boys",
    },
    paragraphs: [
      `Jane began playing volleyball in high school and fell in love with the sport.
      From there, she went on to play in college.  She has been coaching at all levels for the past 15 years.
      On the club side, Jane coached club for 4 years then took some time off to focus on family.  On the high school side,
      Jane spent 8 years coaching both boys and girls at St. Augustine High School where they won 6 region titles
      and made a few bids at state.  Most recently, she coached the 16-3 Sidewinder Boys team and will be starting
      her second year as the Mica Mountain High School boys head varsity coach.`,
    ],
  },
  //Leah
  lmcadams: {
    name: "Leah McAdams",
    image: {
      src: leah,
      caption: "Leah played for Eastern Washington University",
    },
    paragraphs: [
      `Leah started playing volleyball when she was 7 years old. She
      started 4 years on Varsity at Sabino High School, played 7 years
      for Zona Volleyball Club, and most recently finished her Division
      1 collegiate career at Eastern Washington University. After moving
      back to Tucson, Leah attended the University of Arizona where she
      graduated with her bachelor’s degree in Mechanical Engineering.
      She has been coaching summer camps and developmental clinics since
      2016, and has been on staff with Sidewinder since 2023.`,
    ],
  },
  //SHANE
  smcadams: {
    name: "Shane McAdams",
    image: {
      src: shane,
      caption: "Shane McAdams head coaches Sidewinder's 15s team",
    },
    paragraphs: [
      `Shane McAdams graduated from Ironwood Ridge Highschool in 2014. He
    played collegiately at Quincy University in Illinois in the MIVA, where he played
    middle as well as opposite and outside his senior year. Shane also played
    internationally in the summer of 2018 as part of a team made up of USA athletes to
    play in Amsterdam against teams from around the world.`,
      `Shane is currently the varisty assistant and middle blocker coach
    at Catalina Foothills High School.  Shane has been coaching at the high school level
    for 4 years.`,
    ],
  },
  //Nickel Helin
  nhelin: {
    name: "Nikki Helin",
    image: {
      src: nickel,
      caption:
        "Nikki Helin head coaches the University of Arizona Women's Club team",
    },
    paragraphs: [
      `Nikki Helin has been a coach for Sidewinder since 2022 when she was the head coach for the 16-1 team.  Outside
    of Sidewinder, Nikki is a head coach for the University of Arizona's women's club team.
    Nikki is very active in the Tucson volleyball community and has a passion for beach volleyball
    specifically, playing in many local competitive tournaments.  Nikki graduated from NAU
    in 2022.  Before that, she was a graduate of Desert Christian High School where she was
    a 4-year varsity player.`,
    ],
  },
  // Aiden V
  avanhoesen: {
    name: "Aiden Van Hosen",
    image: {
      src: aidenv,
      caption: "Aiden coaching at the 2024 USAV Nationals",
    },
    paragraphs: [
      `Aiden Van Hoesen first started coaching 3 years ago.
    Aiden has been on staff coaching boys club at Arizona Premier Volleyball the past two years, including assisting the 14-1 team this past season.
    Aiden is entering his third year at the University of Arizona where he also competes on the men's volleyball team as a setter.`,
      `Aiden was a 1st team all conference player at Cienega High School and led his team to a State Runner-Up finish his senior year.`,
    ],
  },
  //ALEXA
  ahoover: {
    name: "Alexa Hoover",
    image: {
      src: alexa,
      caption: "Coach Alexa coaching the 14s team in 2023",
    },
    paragraphs: [
      `Coach Alexa Hoover has been a head coach for Sidewinder girls since 2022 and an assistant coach for the boys since 2023.
    Alexa previously played on the Arizona women’s club volleyball team as a middle blocker.
    Before coming to Tucson, she played club for 7 years for Oklahoma City Charge Volleyball
    and Oklahoma Peak Performance Volleyball. Alexa also played sand volleyball for 4 years
    for OKC Sharks.`,
    ],
  },
  //Julissa
  jserna: {
    name: "Julissa Serna",
    image: {
      src: jules,
      caption: "Jules also coaches at Catalina Foothills HS",
    },
    paragraphs: [
      `Coach Julissa is entering her first year with Sidewinder but has years of experience coaching
      at varying skill levels.  Julissa has coached girl's at Catalina Foothills for 2 years as the JV-B coach.
      Before that, she has coached at both Orange Grove and Esperero Canyon Middle school as the head 6th, 7th and 8th grade coach.
      During that time, she also coached 2 seperate teams at Club Cactus Juniors and for the U of A Dave Rubio summer volleyball camps.`,
      `A Tucson volleyball native, Julissa was a 4 year volleyball player at Flowing Wells High School
      before she graduated in 2020. While in highschool, Julissa also played for Club Cactus Junior Volleyball Club.`,
    ],
  },
  //Aurora
  amolina: {
    name: "Aurora Chagollan-Molina",
    image: {
      src: aurora,
      caption: "Aurora some of her middle school athletes",
    },
    paragraphs: [
      `Aurora began playing volleyball in middle school and moved up the ranks
      in high school and club volleyball in Oregon. After joining the Navy, she continued to
      seek out playing opportunities, from NATO teams in Italy, competitive beach volleyball in
      Spain, local leagues in Germany and was proud to play on the U.S. Women’s Navy Europe team.
      Her love of the sport is contagious, both of her sons play for Sidewinder and last summer the
      team took bronze at the Boys Junior Nationals in Salt Lake City.`,
      `Aurora uses her experience in developing athletes’ character, teamwork, and leadership
        while coaching both boys and girls volleyball at Old Vail Middle School. This is her first
        year coaching with Sidewinder Volleyball Academy and she is looking forward to sharing her love of the game.`,
      `Aurora is an air traffic controller and has a MSA from Embry-Riddle Aeronautical University.`,
    ],
  },
  // Carson
  cmott: {
    name: "Carson Mott",
    image: {
      src: carson,
      caption: "Carson Mott coaching at the SoCal Cup",
    },
    paragraphs: [
      `Carson was born and raised in Tucson, and played both high school and club volleyball in the area.
      He played for Sidewinder (then called APV) from 2017 to 2020 and graduated from Cienega High School.
      During his time at Cienega, in addition to winning a state championship in 2017, Carson received numerous
      accolades including Freshman of the Year, All-Conference all 4 years, and 2020 Arizona Scholar Athlete of the Year.
      He also competed for the state of Arizona in Oklahoma and Florida while playing at the USA Volleyball
      High Performance Championships.`,
      `After graduating from Cienega, Carson attended Ottawa University in Surprise, Arizona, where he played volleyball
      his freshman year. While in Surprise, he coached two seasons with Hanai Volleyball Club.  He then transferred to
      the University of Arizona, where he is currently a manager for the women’s volleyball team. Since being back in
      Tucson, Carson has been an assistant coach for Sidewinder's 18-1 team.`,
      `Carson grew up in the gym as his mom has been coaching for 20+ years, and both his older brother and sister went
      on to play volleyball in college as well.  Volleyball is a family affair for the Motts and this has fostered Carson’s
      love and passion for the game. He seeks to be involved in volleyball in any way possible. Carson is currently a
      sophomore at the University of Arizona where he is studying to pursue a career in sports administration. He hopes to
      continue to be involved in athletics in both his personal and professional life.`,
      `Carson said this about why he is a coach… “The game of volleyball has shaped me into the person I am today.
      The lessons I have learned and the memories I have made while being a part of a team and competing at a high level
      are things I will carry with me throughout my life. I hope to impart these things on the next generation of athletes
      and help them recognize their value on and off the court.”`,
    ],
  },
  //CARLEE
  ctofel: {
    name: "Carlee Tofel",
    image: {
      src: carlee,
      caption: "Carlee at the Sidewinder Summer Skills Clinics.",
    },
    paragraphs: [
      `Carlee has been coaching for Sidewinder since 2022. She coached at our 2023 summer clinics and was an assistant
      coach for the Sidewinder girls 16-1 team and boys 18-2 team. Currently, she is the head coach for the Sidewinder
      girls 14-2 team. She also assisted the Catalina Foothills High School varsity girls and boys programs during the
      past school year. `,
      `After playing volleyball as a student at Catalina Foothills High School, Carlee continued to play middle for 4
      years at the University of Arizona on the women’s club team until graduating in Spring of 2022. She played club
      volleyball for 5 years in Tucson while in middle and high school on Dinamo and Sky Islands club teams. `,
    ],
  },
  //Matea
  mvillegas: {
    name: "Matea Villegas",
    image: {
      src: matea,
      caption: "Matea is the JV-A head coach at RUHS",
    },
    paragraphs: [
      `Coach Matea has been a Sidewinder Coach since 2023 and is also the Head Coach of Tucson High Boy’s Volleyball and Head Coach of Cienega Girls’ Volleyball. Matea started her coaching career during the Fall of 2022 and has coached at St. Augustin Catholic High School and Rincon/University High School. Before that, she played on all three levels for Rincon/University High School’s volleyball team and topped off her senior year helping the varsity team win the TUSD Volleyball Tournament in 2019. During the off-season of school volleyball, she played for Redington Volleyball Club and Desert STARS Volleyball Club.`,
      `Matea aspires to further her volleyball career as a coach, and aims to share her volleyball knowledge with Sidewinder athletes. Her coaching philosophy is centered on reinforcing athletes’ confidence and skill progression.`,
    ],
  },
  //Burke
  mburke: {
    name: "Matt Burke",
    image: {
      src: mattb,
      caption: "Matt coaching the 2023 17-1 boys",
    },
    paragraphs: [
      `Matthew Burke is currently an undergrad student studying Political Science
    and Spanish at the University of Arizona. He has been coaching with Sidewinder since the
    beginning of this past summer, and coached at Rincon/University High school this past year.
    Matthew played varsity volleyball for RUHS his junior and senior year, earning all conference
    recognitions.  Matthew also played for Sidewinder (then called APV) his senior year of high school.`,
    ],
  },
  //SAM
  sgillman: {
    name: "Sam Gillman",
    image: {
      src: sam,
      caption: "Sam Gillman at the University of Arizona",
    },
    paragraphs: [
      `Coach Sam Gillman is a recent graduate from the University of
    Arizona. At the U of A, Sam played 4 years of Collegiate Club Volleyball as a
    Libero. During his 4 years, he led his team to a National Championship in Men’s
    Division III his Senior year. Sam also won the U of A 2022 Outstanding Sportsperson
    of the Year Award his Senior year for his loyalty, dedication, and hard work to the
    U of A Men’s Club Volleyball Team.`,
      `Before starting college at Arizona, Sam attended and played
    volleyball for New Trier Township High School in Illinois. Sam also played for
    Vortex Volleyball Club, where he played 4 years as an Outside Hitter and 2 years as
    a Libero.  Sam is the program Libero coach at Catalina Foothills High School.`,
    ],
  },
  // Charlie de la Vara
  cdelavera: {
    name: "Charlie de la Vara",
    // image: {
    //   src: rueben,
    //   caption: "Rueben with the UArizona Women's Club Team",
    // },
    paragraphs: [
      `My name is Coach Charlie and I am excited to be joining the
    coaching staff here at Sidewinder. I have played in two national championships
    as an athlete through the University of Arizona where we won the D3 National
    Championship in 2015 and 2016. With those national Championships I have been
    nominated as 1st team all tournament for 3 years at the University of Arizona
    as well.  I am currently on the roster through the Volleyball League of America
    as a Libero for the Tier II semi professional team Phoenix Valor.`,
      `I have been apart of some amazing coaching opportunities throughout my career
      as well. I was apart of the coaching staff with Cienega High School from
      2014-2017 where we won state. I was fortunate enough to coach a club team in
      MN where we took first in the Nation at USAV 2022 in the USA level.`,
      `I have created my own brand of coaching through years of practice and
      experience that has lead me to coach a wide variety of athletes including
      Canadian Olympians and VNL athletes over seas. I believe in hard work, hustle,
      and being the best person you can be on and off the court. I have sent dozens
      of athletes off to college to further their academics while playing volleyball
      at the next level, and I continue to make sure that every athlete gets the best
      experience possible.`,
      `I am excited to be apart of this organization and cannot
      wait to meet you all. `,
    ],
  },
  // Franklin Donley
  fdonley: {
    name: "Franklin Donley",
    image: {
      src: franklin,
      caption: "Franklin coaching at the 2024 USAV National Championships",
    },
    paragraphs: [
      `Franklin has been a staple in the Tucson volleyball community.
          He has coached in Arizona since 2006, working at Academy of Tucson,
          Catalina, and Tucson High Schools for girls and Salpointe Catholic
          for boys. On the club side, he has coach boys and girls club for
          Redington, RUSH, and Arizona Summit.  Most recently, Franklin took
          his 18-1 team to a bronze finish at Nationals in 2023. Before coming
          to Arizona, Franklin coached High School Boys and Club in Southern
          California.`,
      `Franklin knew he wanted to coach because of how lucky he was to receive
        great coaching when he was learning the game. Franklin says, "I want to
        pass that knowledge down and help players grow and succeed. Not just as
        athletes but as people, too".`,
      `Franklin played high school, club, and JUCO in Southern
        California.`,
    ],
  },
  // Ayda Felix
  afelix: {
    name: "Ayda Felix",
    image: {
      src: ayda,
      caption:
        "Ayda coached the 16-1 team at the 2024 USAV National Championships",
    },
    paragraphs: [
      `Aydalis Felix has coached for two years.`,

      `Aydalis played for the national club level for three years. She
          played volleyball for Rincon/University High School and was on varsity
          for three years. During the 2019-2021 seasons,  she played for Pima
          College, was named the ACCAC 2021 Libero of the year, and the team earned
          a national bid in 2021. In 2022, she played for Fresno Pacific.`,
      ` Aydalis said this about being a coach: “I’m a coach because I want
          to make sure athletes that love the game have an opportunity to continue
          to grow to their potential. I want them to feel like they have the
          support and encouragement.”`,
    ],
  },
  //Cheyenne
  csmith: {
    name: "Cheyenne Smith",
    image: {
      src: cheyenne,
      caption: "Cheyenne coaching at the 2023 SoCal Cup Winter Formal",
    },
    paragraphs: [
      `Coach Cheyenne is entering her first season with Sidewinder Volleyball.  Cheyenne spent 2 years playing
    varsity at Empire High School and Club Cactus as a starting libero, before graduating in 2016.`,
      `She is looking forward to sharing her passion for the game and continuing her volleyball career as a coach!`,
    ],
  },
  //Kyle B
  kboesen: {
    name: "Kyle Boesen",
    image: {
      src: boesen,
      caption: "Kyle at the 2024 USAV National Championships",
    },
    paragraphs: [
      `This will be Kyle Boesen’s first year coaching for Sidewinder. 
      Kyle has been playing volleyball for 7 years and currently plays for the University of Arizona as the starting libero.
      Kyle recently graduated from Catalina Foothills High School where he played as a setter and a libero on varsity for all 4 years.
      Throughout middle and high school Kyle also played club volleyball for Sidewinder (then called APV).  In his 18u year of club,
      Kyle received 1st team all tournament honors at the SoCal cup - ranking him as one of the top liberos in the country.`,
    ],
  },
  // Brianna Denton
  bdenton: {
    name: "Brianna Denton",
    // image: {
    //   src: rueben,
    //   caption: "Rueben with the UArizona Women's Club Team",
    // },
    paragraphs: [
      `Brianna has been coaching and managing volleyball for the
    last 10 years. She started managing for the University of Arizona’s
    Women’s team under David Rubio. As the statistics manager, Brianna was
    exposed to high level volleyball and learned not only more about the game,
    but different coaching styles.  Brianna started coaching for Sidewinder
    (previously APV) 8 years ago and has coached various age groups. For the
    last 3 years she has also been coaching the JV team at Salpointe Catholic
    High School.`,
    ],
  },
  // Tristan Emma
  temma: {
    name: "Tristan Emma",
    image: {
      src: tristan,
      caption: "Tristan coached the 2024 17-1 team",
    },
    paragraphs: [
      `Tristan Emma attends the U of A where he plays for the
    Mens Volleyball team and studies Computer Science. As the starting
    rightside, Tristan and his University of Arizona team won the D3
    National Championship in 2022. This last spring, Tristan played
    middle for the Arizona Mens Team where he helped his team to a
    5th place finish in the D1 National Championships. A graduate of
    Cienega High School and having played for Sidewinder (Previously APV),
    Tristan has been a part of many volleyball teams over the past 7 years.
    Playing all over the court throughout his career, Tristan has gained
    experience in many different aspects of the game. In December, Tristan
    was a volunteer coach at the Cienega High School Holiday Skills Camp.`,
    ],
  },
  // Brett Marx
  bmarx: {
    name: "Brett Marx",
    image: {
      src: brett,
      caption: "Brett coaching at the 2024 USAV National Championships",
    },
    paragraphs: [
      `Brett played 2 seasons of Men's Volleyball at U of A.  His senion year, he
    was part of the 2022 National Championship team and was named 1st
    team all nation as a Middle Blocker. Before college, Brett was a
    captain of the Sabino Varsity team all 4 years. During this time,
    Brett also played 8 seasons of club for Sidewinder (Previously APV).`,
      `Brett started his coaching career last year as the assistant JV coach
    at Salpointe Catholic where he helped lead them to an undefeated season`,
    ],
  },
  // Zach Denton
  zdenton: {
    name: "Zach Denton",
    image: {
      src: zach,
      caption: "Zach coaching at the 2024 USAV Nationals",
    },
    paragraphs: [
      `Zach Denton started coaching 3 years ago after graduating
    from Salpointe Catholic Highschool. Zach played volleyball for 9 years
    including 4 years of highschool and club. He currently attends GCU and
    is studying Criminal Justice, but continues to play recreational and
    intramural volleyball as well as help out with the Salpointe volleyball team.`,
    ],
  },
  // Bri DeVries
  bdevries: {
    name: "Bri DeVries",
    // image: {
    //   src: rueben,
    //   caption: "Rueben with the UArizona Women's Club Team",
    // },
    paragraphs: [
      `Bri currently currently plays collegiate volleyball at Southwestern Christian
        University where I'm majoring in film and kinesiology. Bri played a summer of
        indoor and beach volleyball in both Australia and Hawaii.  In the past, Bri
        has coached the JV team at Mica Mountain High School`,
      `Before college, Bri played four years of varsity volleyball at St. Augustine
      Catholic HS and five years at Club Cactus Junior Volleyball Club. She holds two
      state records for service aces in Arizona.`,
    ],
  },
  // Aiden Keen
  akeen: {
    name: "Aiden Keen",
    image: {
      src: aidenk,
      caption: "Aiden plays for the U of Arizona Men's Team",
    },
    paragraphs: [
      `Aiden Keen started playing volleyball for Sidewinder
      (then called APV) and Cienega high school in 2020. While
      in high school, he contributed to the 2022 state
      championship and received all region honors. Aiden is
      currently going into his Sophomore year at the University
      of Arizona where he plays for the Men's Club Volleyball team.`,
    ],
  },
  // Fiona Rucker
  frucker: {
    name: "Fiona Rucker",
    image: {
      src: fiona,
      caption: "Fiona with the 2024 Girl's 14-1 Team",
    },
    paragraphs: [
      `Fiona has coached boys and girls teams with Sidewinder since 2024. Fiona
      currently is a Senior at Catalina Foothils and has played volleyball
      for 8 years. Since Freshman year, Fiona has played for Salpointe's
      and Catalina Foothills' varsity teams. Fiona has been on each of Zona
      Volleyball Club's top teams since she was 11. She’s very excited
      to coach and advance in her career!`,
    ],
  },
  // Lauren Glasner
  lglasner: {
    name: "Lauren Glasner",
    // image: {
    //   src: rueben,
    //   caption: "Rueben with the UArizona Women's Club Team",
    // },
    paragraphs: [
      `Lauren is entering her first year coaching with Sidewinder.
    She has played volleyball for 6 years, including 3 years with Club Cactus
    as a setter. This past fall, Lauren was the starting setter at Catalina
    Foothills High School. Along with coaching at Sidewinder this year, she
    has coached youth academy clinics with Club Cactus and has assisted with
    Orange Grove Middle School’s 7th grade volleyball team. Lauren is excited
    to begin her coaching career with Sidewinder and to share her love for
    the game!`,
    ],
  },
  // Ryan Call
  rcall: {
    name: "Ryan Call",
    // image: {
    //   src: rueben,
    //   caption: "Rueben with the UArizona Women's Club Team",
    // },
    paragraphs: [
      `Ryan Call grew up in Tucson and is a graduate of Canyon Del Oro
      HS. During his senior year of high school, high school boys volleyball
      finally became an AIA high school sanctioned sport enjoyed by many
      talented athletes to this day.`,
      `After graduating from CDO, Ryan received a volleyball scholarship to
      Rutgers University where he went to school and played volleyball for a
      year.  The summer before his first semester at Rutgers, he played on the
      Molson East Coast Professional Volleyball Tour. Best finishes: 2nd at the
      Virginia Beach Open and the Hamptons Open. Ryan left Rutgers to attend
      Orange Coast College where he earned an associate’s degree.  While at OCC,
      he played competitively on the CBVA for 2 years earning a AAA beach
      ranking, and also competed for two years in the Balboa Bay Club men’s league.`,
      `Ryan returned to Tucson to start a family and to finish his BA degree in
      Elementary Education.  He then began coaching Frosh boys and girls ‘91-‘93,
      JV boys and girls ‘94-‘95, and eventually the varsity boys and girls ’96-‘98 at
      Salpointe Catholic High School.  Both years of frosh boys and Frosh girls the
      teams went undefeated, and the varsity boys and girls teams made appearances in
      the state semi finals.  Ryan was named the Southern Arizona 5A coach of the year
      in 1996.`,
      `Ryan was the volunteer assistant coach for the University of Arizona women’s
      volleyball team in 1998.  The team made the NCAA tournament losing to #1 Long
      Beach State in the second round that year.`,
    ],
  },
  // Jared Chapman
  jchapman: {
    name: "Jared Chapman",
    image: {
      src: jared,
      caption: "Jared with the Marana boy's varsity team",
    },
    paragraphs: [
      `Jared starting coaching in 2022 as an assistant
      for the Marana girl's varsity team and the boy's freshman team.
      He took over as the boy's varsity coach in 2023. Jared played
      varsity volleyball in high school for Mountain View and with
      Sidewinder (then called APV)`,
    ],
  },
  // Brian Bowers
  bbowers: {
    name: "Brian Bowers",
    image: {
      src: bowers,
      caption: "Coach Brian shows off his national championship ring",
    },
    paragraphs: [
      `Coach Brian started his coaching career at Club Cactus in 2000
        and served as the boy's head varsity coach at Tucson High for many years.
        Most recently, Brian was a 12u coach at Club Cactus for his youngest daughter's
        team and the Beach Volleyball Varsity Coach at Catalina Foothills HS. Brian
        also helps his step-son Coach Andrew with the indoor team in the fall.`,
      `Coach Brian played 1 year of collegiate club at ASU, followed by 4 years of
      D1 volleyball at Rutgers, and then capped off his playing career with 2 more
      years of club vollebyall at U of A where he won back to back national championships.`,
    ],
  },
  // Ava Lopez
  alopez: {
    name: "Ava Lopez",
    // image: {
    //   src: jared,
    //   caption: "Jared with the Marana boy's varsity team",
    // },
    paragraphs: [`Coming Soon!`],
  },
  // Jewelya Brambila
  jbrambila: {
    name: "Jewelya Brambila",
    // image: {
    //   src: jared,
    //   caption: "Jared with the Marana boy's varsity team",
    // },
    paragraphs: [`Coming Soon!`],
  },
  // Kevin Rountree
  krountreee: {
    name: "Kevin Rountree",
    image: {
      src: kevin,
      caption: "Kevin during the 2021 Girl's State Championship Tournament",
    },
    paragraphs: [
      `Kevin Rountree, a Tucson native, played volleyball
      as a middle at Catalina Magnet High School. He helped lead his
      team to a 3rd place finish in 2009. Kevin played for 4 years
      under hall of fame coach Heather Moore Martin and has been on
      her coaching staff since he graduated. He assisted in winning
      back-to-back state championships with the Catalina boys
      volleyball team in 2010 and 2011. At Salpointe Catholic High
      School, he also helped bring back-to-back indoor girls volleyball
      state championships in 2016, 2017, and one in 2020, along with a
      four-peat in beach volleyball from 2021 to 2024.`,
      `Kevin has coached at many different clubs including AVP (now Sidewinder)
      and all ages from 12 & under to the college club level at the University
      of Arizona. Kevin is excited to share his 15 years of coaching experience
      to continue to help grow the game of volleyball for girls and boys in Tucson! `,
    ],
  },
  // Cheyenne Rice
  crice: {
    name: "Cheyenne Rice",
    image: {
      src: cheyenner,
      caption: "",
    },
    paragraphs: [
      `Coach Rice has been coaching indoor and beach volleyball since 2021 and started with Sidewinder in 2024. Coach
      Cheyenne has been playing and loving the sport of volleyball since she was 9 years old. She played both beach
      and indoor club volleyball for about 7 years and finally leaned into the beach side to make a career out of it.
      She played collegiately D1 for 5 years at both Texas A&M University - Corpus Christi (Fall 2019-Fall 2020) and
      at San Jose State University (Spring 2021- Spring 2024). In her last year she revived the Class Act award for
      being a great teammate, respectful to all those around her and for great sportsmanship when competing. Coach
      Cheyenne also broke a school record with her partner for most wins as a pair in school history in spring of 2024.`,
      `She is now the head Varsity and Junior Varsity coach for her first season with the girls high school volleyball
      team at Santa Rita High School and plans to coach club volleyball in the spring!`,
    ],
  },
  // Anya Lang
  alang: {
    name: "Anya Lang",
    // image: {
    //   src: jared,
    //   caption: "Jared with the Marana boy's varsity team",
    // },
    paragraphs: [`Coming Soon!`],
  },
  // Adriana Dewitt
  adewitt: {
    name: "Adriana Dewitt",
    // image: {
    //   src: jared,
    //   caption: "Jared with the Marana boy's varsity team",
    // },
    paragraphs: [`Adriana is entering her first season with Sidewinders.
      She is currently leading the Women’s JV team at Marana High School,
      and has two seasons of club coaching experience. She began playing
      volleyball at the age of 7, continued through her high school career
      at Mountain View High School as a starting libero and defensive
      specialist. She also played competitively in club, where her team
      achieved 1st place in the Arizona Region in 2017. She focuses on a
      holistic approach to coaching - physical wellbeing, emotional health,
      skill development, and mental growth.`],
  },
  // Johan Brambila
  jobrambila: {
    name: "Johan Brambila",
    // image: {
    //   src: jared,
    //   caption: "Jared with the Marana boy's varsity team",
    // },
    paragraphs: [`This will be Johan’s first year coaching with Sidewinder Volleyball Academy!
      He has been playing volleyball for 5 years and is currently a student at the University
      of Arizona. Johan has played on all of Sidewinders 1s teams, and helped lead his 18-1s
      team to a runner up finish at the SoCal Cup Winter formal in 2023. Johan played as a
      varsity libero for 3 years at University High School, where he helped lead his team to
      win the TUSD Championship his senior year and also break the career record for digs! Johan
      cannot wait to share his knowledge of volleyball with everyone! `],
  },
  // Scarlett Lopez
  slopez: {
    name: "Scarlett Lopez",
    // image: {
    //   src: jared,
    //   caption: "Jared with the Marana boy's varsity team",
    // },
    paragraphs: [`This is Scarlett Lopez’s first year coaching at Sidewinder.
      She is currently a senior at University High School and has played volleyball
      for 8 years. Scarlett has played on the Rincon-University Varsity team and has
      been Varsity Captain for 2 years. During the off season, she’s played for Zona
      Volleyball Club, Tucson Christian (Beach) Volleyball League, and frequently
      attends Pueblo Center’s open gyms. `],
  },
  // Laya Rocha
  larocha: {
    name: "Laya Rocha",
    // image: {
    //   src: jared,
    //   caption: "Jared with the Marana boy's varsity team",
    // },
    paragraphs: [`Laya started playing volleyball when she was 10.
      She played for four years at University High School, 3 of which
      were played on the varsity team. Laya then went on to continue
      as an outside hitter at Knox College in Galesburg, Illinois. Now
      back in Tucson, Laya coaches at St. Augustine High School and started
      on the Sidewinder staff in 2024.`],
  },
  // Cassie Leary
  cleary: {
    name: "Cassie Leary",
    // image: {
    //   src: jared,
    //   caption: "Jared with the Marana boy's varsity team",
    // },
    paragraphs: [`Cassie started with Sidewinder in 2024. Before becoming a current
      student at the University of Arizona, Cassie played volleyball at a competitive
      level for four years. During her club seasons, she competed on the highest level
      1’s team at Club Cactus for three years. Cassie finished her senior year with
      all-region first team recognition, while also being one of the top blockers in
      Southern Arizona. She is so excited to stay involved with the sport in a new way!`],
  },


  /* INACTIVE BELOW */

  // Elyssa Evano
  eevano: {
    name: "Elyssa Evano",
    // image: {
    //   src: jared,
    //   caption: "Jared with the Marana boy's varsity team",
    // },
    paragraphs: [`Coming Soon!`],
    inactive: true,
  },
  // Esperanzo Harper
  eharper: {
    name: "Esperanza Harper",
    // image: {
    //   src: jared,
    //   caption: "Jared with the Marana boy's varsity team",
    // },
    paragraphs: [`Coming Soon!`],
    inactive: true,
  },
  // Nikki Johnson
  njohnson: {
    name: "Nikki Johnson",
    // image: {
    //   src: jared,
    //   caption: "Jared with the Marana boy's varsity team",
    // },
    paragraphs: [`Coming Soon!`],
    inactive: true,
  },
  // Jrue Jantz
  jjantz: {
    name: "Jrue Jantz",
    // image: {
    //   src: jared,
    //   caption: "Jared with the Marana boy's varsity team",
    // },
    paragraphs: [`Coming Soon!`],
    inactive: true,
  },
  // Drubio
  drubio: {
    name: "Dave Rubio",
    // image: {
    //   src: jared,
    //   caption: "Jared with the Marana boy's varsity team",
    // },
    paragraphs: [`Coming Soon!`],
    inactive: true,
  },
};

export default Coaches;
